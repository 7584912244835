import React from "react";

import { graphql } from "gatsby";

import CheckoutPage from "../../../components/pages/Pellets/CheckoutPage";
import Robots from "../../../components/shared/Meta/Robots";
import Page from "../../../components/shared/Page";
import PelletsDataProvider from "../../../providers/PelletsDataProvider";
import Error404Page from "../../404";

const HolzpelletsBestellungPage = ({ params: { checkoutStep } }) =>
    checkoutStep == null ? (
        <Error404Page />
    ) : (
        <Page>
            <Robots index={false} />

            <PelletsDataProvider>
                <CheckoutPage {...{ checkoutStep }} />
            </PelletsDataProvider>
        </Page>
    );
export default HolzpelletsBestellungPage;

export const query = graphql`
    query {
        page: otherPagesYaml(slug: { eq: "holzpellets" }) {
            ...Page
        }
    }
`;
